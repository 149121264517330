<template>
  <div class="wrap">
    <div class="input_wrap">
      <el-input placeholder="请输入题目ID"
                v-model="keywords">
        <i slot="suffix"
           @click="searchTopic"
           class="el-input__icon el-icon-search"
           style="font-size:20rem;pointer:cursor"></i>
      </el-input>
    </div>
    <div class="index_warp">
      <div class="left">
        <Top ref="leftRef" />
      </div>
      <div class="right">
        <Bottom ref="rightRef" />
      </div>
    </div>
    <div class="autoModal"
         v-if="autoModalFlag">
      <div class="openButton">

        <el-button type="warning"
                   @click="toBuy"
                   class="btntext"><span class="text">开通VIP享受全部功能</span></el-button>
      </div>
    </div>
  </div>
</template>
<script>
import Top from './components/top.vue'
import Bottom from './components/bottom.vue'
import { getTopic } from '@/api/topic.js'
export default {
  data () {
    return {
      keywords: '',
       autoModalFlag: false,
    }
  },
   mounted () {
  
    let userInfo = JSON.parse(window.localStorage.getItem('userInfo'));
    if (userInfo.user_type_id == 14 && !userInfo.vip_end_time) {
      this.autoModalFlag = true
    }
  },
  methods: {
    toBuy () {
      this.$router.push('/buyVip')
    },
    async searchTopic () {
      const { data } = await getTopic({
        question_id: this.keywords
      })
      if (data.list && data.list.length) {
        this.$router.push('showTopic?topic_id=' + this.keywords)
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    // let keep_alive_path = ['/group_test', '/myPaper', '/analysis', '/choiceness', '/knowledge', '/wholepaper_list', '/discuss', '/chapter']
    let keep_alive_path = ['/analysis', '/discuss', '/chapter']
    if (keep_alive_path.indexOf(to.path) != -1) {
      to.meta.keep = true;
    }
    next();
  },

  components: {
    Top, Bottom
  },

}
</script>

<style lang="scss" scoped>
  .autoModal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 1.5;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
    .btntext {
      position: fixed;
      left: 800rem;
      top: 700rem;
      .text {
        color: var(--light-333333, #333);
        text-align: center;

        font-size: 24rem;
        font-style: normal;
        font-weight: 600;
        line-height: 36rem; /* 150% */
      }
    }
    .openButton {
      ::v-deep .el-button {
        display: flex;
        width: 360rem;
        height: 64rem;
        justify-content: center;
        align-items: center;
        gap: 10rem;
        flex-shrink: 0;
        border-radius: 8rem;
        background: linear-gradient(100deg, #fdecc9 0%, #e9c383 100%);
        box-shadow: 0rem 8rem 16rem 0rem rgba(0, 0, 0, 0.1);
      }
    }
  }
.index_warp {
  width: 100%;
  display: flex;
  // height: calc(98.5vh - 75rem);
  .left {
    display: flex;
    width: 59.9vw;
    margin-top: 1.5vh;
    height: 100%;
    margin-left: 3vw;
  }
  .right {
    flex-shrink: 0;
    width: 27.6vw;
    margin-top: 1.5vh;
    height: 100%;
    margin-left: 3vw;
  }
}
.wrap {
  width: 100%;
}
.input_wrap {
  width: 400rem;
  // position: absolute;
  // top: 28rem;
  // right: 44rem;
  margin-top: 20rem;
  margin-left: 3vw;
  cursor: pointer;
  ::v-deep .el-input--medium .el-input__inner {
    border-radius: 20rem;
    height: 44rem !important;
    line-height: 44rem;
    font-size: 18rem;
  }
  ::v-deep .el-input--medium .el-input__icon {
    margin-right: 20rem;
    line-height: 44rem;
    width: auto;
  }
}
</style>