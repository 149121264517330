<template>
  <div class="bottom_warp">
    <div class="progress"
         :style="userInfo.show_discuss == 0?'height: 900rem;':''">
      <div class="title">
        <span>{{userInfo.edu_grade_id==1?'备考进度':'学习进度'}}</span>
        <span @click="toPage('/notPress')">更多</span>
      </div>
      <div class="list">
        <div class="item"
             v-for="item in paperList"
             :key="item.user_paper_id">
          <div class="paper_name">
            <img src="@/assets/index/组卷测试.png"
                 alt="">
            {{item.template_name}}
          </div>
          <div class="paper">
            {{item.paper_desc}}
          </div>
          <div class="btn_wrap">
            <div class="btn"
                 @click="doPaper(item)">
              继续做卷
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="progress1"
         v-if="userInfo.show_discuss == 1">
      <div class="title">
        <span>线上答疑</span>
        <span @click="toPage('/discuss')">全部</span>
      </div>
      <div class="list">
        <div class="item"
             :style="{'margin-top':index == 0?'':'20rem'}"
             v-for="(item,index) in discussList"
             @click="toInfo(item)"
             :key="item.discuss_id">
          <div class="subject">
            {{item.board_name}}
          </div>
          <div class="head">
            {{item.discuss_title}}
          </div>
          <div class="content">
            {{item.discuss_content}}
          </div>
          <div class="btn_wrap">
            <div class="btn">
              查看回复
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeDiscuss } from '@/api/discuss.js'
import { getMyPaper } from '@/api/my_paper.js'

export default {
  data () {
    return {
      discussList: [],
      paperList: [],
      userInfo: {},

    }
  },
  mounted () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    this.getPaper()
    this.getDiscuss()
  },
  methods: {
    async getPaper () {
      let params = {
        status: 2,
        page: 1,
        limit: 5
      }
      const { data } = await getMyPaper(params)
      this.paperList = data.volist
    },
    toPage (path) {
      this.$router.push(path)
    },
    async getDiscuss () {
      const { data } = await getHomeDiscuss()
      this.discussList = data.list
    },
    doPaper (row) {
      this.$router.push('/doPaper?type=2&form=' + JSON.stringify({ user_paper_id: row.user_paper_id }))
    },
    toInfo (item) {
      this.$router.push('/discuss_info?discuss_id=' + item.discuss_id)
    }
  }
}
</script>

<style lang="scss" scoped>
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* 滚动条上的按钮 (上下箭头). */
::-webkit-scrollbar-button {
  display: none;
}
/* 滚动条上的滚动滑块. */
::-webkit-scrollbar-thumb {
  background-color: rgba(202, 202, 210, 0.8);
  border-radius: 50px;
  cursor: pointer;
}
/* 滚动条没有滑块的轨道部分 */
::-webkit-scrollbar-track-piece {
  border-radius: 20px;
}
/* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
::-webkit-scrollbar-corner {
  background: transparent;
}
.bottom_warp {
  overflow: hidden;
  margin-right: 2.14vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  .progress {
    flex-shrink: 0;
    background: #ffffff;
    box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
    border-radius: 10rem;
    height: 480rem;
    max-height: 52%;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 89rem;
      border-bottom: 1rem solid #e5e5e5;
      span {
        &:nth-child(1) {
          color: #131313;
          font-size: 24rem;
          font-weight: bold;
          text-indent: 30rem;
        }
        &:nth-child(2) {
          color: #999999;
          font-size: 24rem;
          margin-right: 29rem;
          cursor: pointer;
        }
      }
    }
    .list {
      height: calc(100% - 90rem);
      overflow-y: auto;
      .item {
        height: 179rem;
        font-size: 24rem;
        margin-left: 30rem;
        margin-right: 30rem;
        border-bottom: 1rem solid #e5e5e5;
        overflow: hidden;
        .paper_name {
          margin-top: 30rem;
          font-weight: bold;
          color: #000000;
          display: flex;
          align-items: center;
          img {
            width: 24rem;
            height: 24rem;
            margin-right: 20rem;
          }
        }
        .paper {
          font-size: 22rem;
          font-weight: 500;
          color: #999999;
          // height: 55rem;
          margin-top: 15rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .btn_wrap {
          display: flex;
          justify-content: flex-end;
          .btn {
            width: 150rem;
            height: 44rem;
            background: #f1f6f9;
            border-radius: 40rem;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;
            color: #2196f3;
            font-size: 22rem;
            margin-bottom: 18rem;
            margin-top: 10rem;
          }
        }
      }
    }
  }

  .progress1 {
    flex-shrink: 0;
    height: 380rem;

    background: #ffffff;
    box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
    border-radius: 10rem;
    margin-top: 40rem;
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 83rem;
      span {
        &:nth-child(1) {
          color: #131313;
          font-size: 24rem;
          font-weight: bold;
          text-indent: 30rem;
        }
        &:nth-child(2) {
          color: #999999;
          font-weight: bold;
          font-size: 22rem;
          margin-right: 30rem;
        }
      }
    }
    .list {
      width: 100%;
      height: calc(100% - 103rem);
      overflow-y: auto;
      .item {
        height: 274rem;
        background: #f6f6f6;
        border-radius: 5rem;
        margin-left: 30rem;
        overflow: hidden;
        .subject {
          color: #2196f3;
          font-weight: bold;
          font-size: 24rem;
          border-left: 4rem solid #2196f3;
          margin-top: 20rem;
          text-indent: 10rem;
        }
        .head {
          font-size: 24rem;
          height: 31rem;
          font-weight: bold;
          color: #000000;
          margin: 14rem 20rem 20rem 18rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .content {
          margin-left: 20rem;
          height: 86rem;
          font-size: 22rem;
          font-weight: 500;
          color: #999999;

          word-break: break-all;
          text-overflow: ellipsis;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
        .btn_wrap {
          display: flex;
          justify-content: flex-end;
          font-weight: bold;
          color: #ffffff;
          font-size: 22rem;
          .btn {
            width: 150rem;
            height: 44rem;
            background: #2196f3;
            border-radius: 40rem;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20rem;
            margin-right: 20rem;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>