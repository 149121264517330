<template>
  <div>
    <el-dialog title=""
               width="90%"
               :show-close="false"
               :visible.sync="dialogTableVisible">
      <div class="fixed_wrap">
        <img src="@/assets/leftcontroller/返回.png"
             @click="back"
             class="fixed_back_btn"
             alt="">
      </div>

      <div class="content">特色功能</div>
      <div class="tabs2"
           id="wl">

        <div class="line">
          <div class="tab"
               @click="toPage('/interesting')">
            <img src="@/assets/index/趣味训练.png"
                 alt="">
            趣味训练
          </div>
          <div class="tab"
               @click="toPage('/goodArticle')">
            <img src="@/assets/index/meiwen.png"
                 alt="">
            美文记忆
          </div>
          <div class="tab"
               @click="toPage('/wordMemory')">
            <img src="@/assets/index/word.png"
                 alt="">
            单词记忆
          </div>
          <div class="tab"
               @click="toPage('/physicalMemory')">
            <img src="@/assets/index/wuli.png"
                 alt="">
            思维导图
          </div>

        </div>

      </div>
      <div class="tabs2"
           id="wl">

        <div class="line">
          <!-- <div class="tab"
               @click="toPage('/capability1')">
            <img src="@/assets/index/关键能力训练.png"
                 alt="">
            关键能力
          </div>
          <div class="tab"
               @click="toPage('/capability2')">
            <img src="@/assets/index/学科素养训练.png"
                 alt="">
            学科素养
          </div> -->

        </div>

      </div>

    </el-dialog>
  </div>

</template>

<script>
export default {
  data () {
    return {
      dialogTableVisible: false,
      userInfo: {},

    }
  },
  async created () {

  },
  mounted () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
  },
  methods: {
    back () {
      this.dialogTableVisible = false
    },
    toPage (path) {
      this.$router.push(path)
    },
  }

}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 10rem;
  height: 90vh;
  margin-top: 5vh !important;
}
.fixed_wrap {
  width: 50rem;
  height: 50rem;
  margin-top: -40rem;

  .fixed_back_btn {
    cursor: pointer;
    top: 15rem;
    left: 25rem;
    width: 50rem;
  }
}
.content {
  display: flex;
  justify-content: center;
  font-size: 35rem;
  color: #333333;
  font-weight: bold;
}

.tabs2 {
  // width: 1150rem;
  // background: #ffffff;
  // box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
  // border-radius: 10rem;
  // border: 1rem solid #ececec;
  // flex-shrink: 0;
  width: 100%;
  // overflow-x: auto !important;
  // height: 480rem;
  padding-bottom: 76rem;
  // max-height: 52%;

  ::-webkit-scrollbar {
    width: 10px; //定义滚动条宽度
    height: 10px; //定义滚动条高度
    border-radius: 10px; //定义滚动条圆角
    background-color: rgba(240, 240, 240, 0.1); //定义滚动条颜色
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::v-depp .-webkit-scrollbar-track {
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5); //定义轨道阴影
    border-radius: 10px; //定义轨道圆角
    background-color: rgba(4, 56, 114, 0.5); //定义轨道颜色
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px; //定义滑块圆角
    box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5); //定义滑块阴影
    background-color: rgba(2, 33, 54, 0.5); //定义滑块颜色
  }
  .line {
    display: flex;
    justify-content: flex-start;
    overflow: hidden;
    margin-left: 4vw;
    margin-right: 4vw;
    // width: inherit;
    width: 108%;
    &:nth-child(1) {
      margin-top: 5vh;
    }
    &:nth-child(2) {
      margin-top: 5.2vh;
    }
    .tab {
      width: 20%;
      // height: 5.625em;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 24rem;
      font-weight: bold;
      color: #333333;
      img {
        width: 100rem;
        margin-bottom: 20rem;
      }
    }
  }
}
</style>