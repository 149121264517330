<template>
  <div class="top_warp">
    <div class="tabs"
         id="wl">

      <div class="line">
        <div class="tab"
             v-if="userInfo.edu_grade_id == 1"
             @click="toPage('/group_test')">
          <img src="@/assets/index/zujuan.png"
               alt="">
          组卷测试
        </div>
        <div class="tab"
             @click="toPage('/wholepaper')">
          <img src="@/assets/index/zhengjuan.png"
               alt="">
          整卷测试
        </div>
        <div class="tab"
             @click="toPage('/choiceness')">
          <img src="@/assets/index/jingxuan.png"
               alt="">
          教师试卷
        </div>

        <!-- <div class="tab"
             v-if="userInfo.edu_grade_id == 1">
          <img src="@/assets/index/kaodian.png"
               @click="toPage('/testing_test')"
               alt="">
          考点训练
        </div> -->
        <div class="tab"
             @click="toPage('/chapter')">
          <img src="@/assets/index/趣味训练.png"
               alt="">
          章节训练
        </div>
        <div class="tab">
          <img src="@/assets/index/kaodian.png"
               @click="toPage('/knowledge')"
               alt="">
          考点训练
        </div>
        <div class="tab"
             v-show="userInfo.edu_grade_id !== 1"
             @click="toPage('/capability2')">
          <img src="@/assets/index/学科素养训练.png"
               alt="">
          学科素养训练
        </div>

      </div>

      <div class="line">

        <!-- <div class="tab"
             @click="toPage('/choiceness')">
          <img src="@/assets/index/jingxuan.png"
               alt="">
          精选试卷
        </div> -->
        <div class="tab"
             v-show="userInfo.edu_grade_id == 1"
             @click="toPage('/capability2')">
          <img src="@/assets/index/学科素养训练.png"
               alt="">
          学科素养训练
        </div>

        <div class="tab"
             @click="toPage('/capability1')">
          <img src="@/assets/index/关键能力训练.png"
               alt="">
          关键能力训练
        </div>

        <div class="tab">
          <img src="@/assets/index/ziyou.png"
               @click="toPage('/refrence')"
               alt="">
          自由选题
        </div>
        <div class="tab"
             @click="toPage('/myPaper')">
          <img src="@/assets/index/wodeshijuan.png"
               alt="">
          我的试卷
        </div>
        <!-- <div class="tab"
             @click="toPage('/chapter')">
          <img src="@/assets/index/zhishidian.png"
               alt="">
         章节训练
        </div> -->
        <!-- <div class="tab"
             @click="toPage('/interesting')">
          <img src="@/assets/index/趣味训练.png"
               alt="">
          趣味训练
        </div>
        <div class="tab"
             @click="toPage('/goodArticle')">
          <img src="@/assets/index/meiwen.png"
               alt="">
          美文记忆
        </div>
        <div class="tab"
             @click="toPage('/wordMemory')">
          <img src="@/assets/index/word.png"
               alt="">
          单词记忆
        </div>
        <div class="tab"
             @click="toPage('/physicalMemory')">
          <img src="@/assets/index/wuli.png"
               alt="">
          物理思维导图
        </div> -->
        <div class="tab"
             @click="openTabDialog">
          <img src="@/assets/index/特色功能.png"
               alt="">
          特色功能
        </div>
      </div>

    </div>

    <div class="tabs1">
      <div class="head">
        <span>
          考点弱点训练
        </span>
        <span @click="toPage('/weakness')">
          更多<img src="@/assets/index/more.png"
               alt="">
        </span>
      </div>
      <div class="line_wrap">
        <div class="item"
             @click="doPaper(item)"
             v-for="(item,index) in list"
             :style="{'margin-right':index == list.length-1?'30rem':''}"
             :key="index">
          <div class="card">
            <div class="content">
              {{item.checkPointDesc || item.knowledgeDesc}}
            </div>
            <div class="line"></div>
            <div class="subject">
              {{item.subjectName}}
            </div>
          </div>
          <div class="name">
            {{item.checkPointDesc || item.knowledgeDesc}}
          </div>
          <div class="score_and_start">
            <span>
              得分率：<span style="color:#FF6147;">{{item.rate}}%</span>
            </span>
            <span>
              <img src="@/assets/index/start.png"
                   v-for="row in item.checkPointStar || item.knowledgePointStar"
                   :key="row"
                   alt="">
            </span>
          </div>
        </div>
      </div>
    </div>
    <tabDialog ref="tabDialog"></tabDialog>
  </div>
</template>

<script>
import { getHomeRefrence, getHomeStudy } from '@/api/home.js'
import tabDialog from './tabDialog.vue'

export default {
  data () {
    return {
      list: [],
      userInfo: {},

    }
  },
  components: {
    tabDialog
  },
  mounted () {
    this.userInfo = JSON.parse(window.localStorage.getItem('userInfo'))
    // if (this.userInfo.edu_grade_id == 1) {
    //   this.getRefrence()
    // } else {
      this.getStudy()
    // }
  },
  methods: {
    openTabDialog () {
      this.$refs.tabDialog.dialogTableVisible = true
    },
    async getRefrence () {
      const { data } = await getHomeRefrence()
      this.list = data
    },
    async getStudy () {
      const { data } = await getHomeStudy()
      this.list = data
    },
    toPage (path) {
      this.$router.push(path)
    },

    doPaper (row) {
      // if (this.userInfo.edu_grade_id == 1) {
      //   let params = {
      //     check_point_id: row.checkPointId,
      //     selecting_checkpoint: -1,
      //     type: 8,
      //   }
      //   this.$router.push('/doPaper?type=3&form=' + JSON.stringify(params))
      // } else {
      console.log('reow', row);

      let params = {
        subject_id: row.subjectId,
        knowledge_id: row.knowledgeId,
        is_weak: 1,
        type: 4,
      }
      this.$router.push('/doPaper?type=5&form=' + JSON.stringify(params))
    }
    // }
  }
}


</script>

<style lang="scss" scoped>
/* 整个滚动条 */
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}
/* 滚动条上的按钮 (上下箭头). */
::-webkit-scrollbar-button {
  display: none;
}
/* 滚动条上的滚动滑块. */
::-webkit-scrollbar-thumb {
  background-color: rgba(202, 202, 210, 0.8);
  border-radius: 50px;
  cursor: pointer;
}
/* 滚动条没有滑块的轨道部分 */
::-webkit-scrollbar-track-piece {
  border-radius: 20px;
}
/* 当同时有垂直滚动条和水平滚动条时交汇的部分. */
::-webkit-scrollbar-corner {
  background: transparent;
}
.top_warp {
  justify-content: space-between;
  align-items: center;
  width: 99%;
  height: 100%;
  overflow: hidden;
  position: relative;
  .tabs {
    // width: 1150rem;
    background: #ffffff;
    box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
    border-radius: 10rem;
    border: 1rem solid #ececec;
    flex-shrink: 0;
    width: 100%;
    // overflow-x: auto !important;
    // height: 480rem;
    padding-bottom: 76rem;
    max-height: 52%;

    ::-webkit-scrollbar {
      width: 10px; //定义滚动条宽度
      height: 10px; //定义滚动条高度
      border-radius: 10px; //定义滚动条圆角
      background-color: rgba(240, 240, 240, 0.1); //定义滚动条颜色
    }

    /*定义滚动条轨道 内阴影+圆角*/
    ::v-depp .-webkit-scrollbar-track {
      box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5); //定义轨道阴影
      border-radius: 10px; //定义轨道圆角
      background-color: rgba(4, 56, 114, 0.5); //定义轨道颜色
    }

    /*定义滑块 内阴影+圆角*/
    ::-webkit-scrollbar-thumb {
      border-radius: 10px; //定义滑块圆角
      box-shadow: inset 0 0 0px rgba(240, 240, 240, 0.5); //定义滑块阴影
      background-color: rgba(2, 33, 54, 0.5); //定义滑块颜色
    }
    .line {
      display: flex;
      justify-content: flex-start;
      overflow: hidden;
      // margin-left: 4vw;
      // margin-right: 4vw;
      // // width: inherit;
      // width: 108%;
      &:nth-child(1) {
        margin-top: 5vh;
      }
      &:nth-child(2) {
        margin-top: 5.2vh;
      }
      .tab {
        width: 20%;
        // height: 5.625em;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 24rem;
        font-weight: bold;
        color: #333333;
        img {
          width: 100rem;
          margin-bottom: 20rem;
        }
      }
    }
  }
  .tabs1 {
    width: 59.5vw;
    background: #ffffff;
    box-shadow: 0rem 0rem 8rem 4rem rgba(234, 234, 234, 0.5);
    border-radius: 10rem;
    flex-shrink: 0;
    height: 380rem;
    margin-top: 3vh;
    overflow-y: hidden;

    .head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: calc(100% - 50rem);
      margin-left: 30rem;
      // height: 59rem;
      margin-top: 30rem;
      margin-bottom: 20rem;
      span {
        &:nth-child(1) {
          font-weight: bold;
          color: #000000;
          font-size: 24rem;
        }
        &:nth-child(2) {
          font-weight: bold;
          color: #999999;
          font-size: 22rem;
          display: flex;
          align-items: center;
          img {
            width: 24rem;
            height: 24rem;
            margin-left: 14rem;
          }
        }
      }
    }
    .line_wrap {
      display: flex;
      overflow: auto;
      .item {
        flex-shrink: 0;
        width: 14.58vw;
        height: calc(100% - 83rem);
        margin-left: 30rem;
        .card {
          height: 162rem;
          background-image: url("../../../assets/index/card_yuwen.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          overflow: hidden;
          .content {
            font-size: 22rem;
            font-weight: 800;
            height: 43rem;
            color: #ffffff;
            margin: 22rem 0 30rem 20rem;
          }
          .line {
            width: 52rem;
            height: 7rem;
            background: #ffffff;
            border-radius: 4rem;
            margin-left: 20rem;
            margin-bottom: 12rem;
          }
          .subject {
            margin-left: 20rem;
            font-size: 22rem;
            font-weight: 500;
            color: #ffffff;
          }
        }
        .name {
          font-size: 22rem;
          font-weight: bold;
          color: #333333;
          margin-top: 16rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .score_and_start {
          margin-top: 16rem;
          width: 100%;
          display: flex;
          justify-content: space-between;
          margin-bottom: 15rem;
          span {
            font-weight: 500;
            font-size: 20rem;
            color: #999999;
          }
          img {
            width: 21rem;
          }
        }
      }
    }
  }
}
</style>